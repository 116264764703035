<template>
  <div>
    <div class="home-bg-warp">
      <div class="content">
        <div class="max-warp">
          <div class="swiper-warp">
            <div class="head_swiper">
              <a-carousel arrows class="slick-thumb" :autoplay="true" :after-change="onChange"
                          v-if="headNewList.length">
                <div slot="customPaging" slot-scope="props">
                  <span class="day">{{ headNewList[props.i].day }}</span>
                  <span class="yeas">{{ headNewList[props.i].years }}</span>
                </div>
                <div slot="prevArrow" class="custom-slick-arrow" style="left: 0px;zIndex: 1">
                  <a-icon type="left" />
                </div>
                <div slot="nextArrow" class="custom-slick-arrow" style="right:0px;">
                  <a-icon type="right" />
                </div>
                <div v-for="head in headNewList" :key="head.id"><img :src="staticDomainURL + head.picture" alt=""></div>
              </a-carousel>
            </div>
            <div class="headlines">
              <div class="head_content" v-if="headNewList[currentHead]"
                   @click="$router.push({path:'details', query:{ id: headNewList[currentHead].id }})">
                <div class="head-top">
                  <div class="top-left">头条新闻</div>
                  <div class="top-right">
                    <a-icon class="eye_icon" type="eye" />
                    {{ headNewList[currentHead].viewnum || 0 }}
                  </div>
                </div>
                <div class="head-title">{{ headNewList[currentHead].pressTitle }}</div>
                <div class="head-info">
                  {{ headNewList[currentHead].summarize }}
                </div>
              </div>
            </div>
          </div>
          <div class="new-warp">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1">
                <span slot="tab"><a-icon type="file-text"></a-icon> 新闻中心</span>
                <div class="news_center">
                  <ul class="news_ul">
                    <li class="news_li" v-for="news in newsList" :key="news.id">
                      <span class="li_time"><a-icon class="time_icon"
                                                    type="clock-circle" /> {{ news.releasetime }}</span><br />
                      <span class="li_title"
                            @click="$router.push({path:'details', query:{ id:news.id }})">{{ news.pressTitle }}</span>
                    </li>
                  </ul>
                  <div class="new_more">
                    <span @click="$router.push({path:'detailsList', query:{ name:'新闻中心' }})">更多</span>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2">
                <span slot="tab"><a-icon type="mail"></a-icon> 通知公告</span>
                <div class="news_center">
                  <ul class="news_ul">
                    <li class="news_li" v-for="notice in noticeList" :key="notice.id">
                      <span class="li_time"><a-icon class="time_icon"
                                                    type="clock-circle" /> {{ notice.releasetime }}</span><br />
                      <span class="li_title"
                            @click="$router.push({path:'details', query:{ id:notice.id }})">{{ notice.pressTitle }}</span>
                    </li>
                  </ul>
                  <div class="new_more">
                    <span @click="$router.push({path:'detailsList', query:{ name:'通知公告' }})">更多</span>
                  </div>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
        <div class="middle-warp" id="middle_warp">
          <div class="management">
            <div class="ment_content">
              <div class="ment_title">经营专题</div>
              <div class="title_line"></div>
              <div class="ment_info">
                <a-row :gutter="15">
                  <a-col span="24" class="info_warp" v-for="(special, index) in specialList" :key="special.id"
                         @click="$router.push({path:'details', query:{ id: special.id }})">
                    <div v-if="index < 1" class="info-img" style="border: 1px solid #ccc;">
                      <div>
                        <img v-if="special.picture" :src="staticDomainURL + special.picture">
                        <img v-else src="~@/assets/img/management1.png">
                      </div>
                    </div>
                    <div class="info-title">
                      {{ special.pressTitle }}
                    </div>
                  </a-col>
                </a-row>
                <div class="more_btn">
                  <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'经营专题' }})">
                    点击查看更多专题
                  </a-button>
                </div>
              </div>
            </div>
            <div class="story-warp">
              <div class="ment_title">一线故事</div>
              <div class="title_line"></div>
              <div class="story_center">
                <a-row :gutter="15">
                  <a-col span="24" class="info_warp" v-for="(story, index) in storyList" :key="story.id"
                         @click="$router.push({path:'details', query:{ id: story.id }})">
                    <div v-if="index < 1" class="info-img" style="border: 1px solid #ccc;">
                      <div>
                        <img v-if="story.picture" :src="staticDomainURL + story.picture">
                        <img v-else src="~@/assets/img/management1.png">
                      </div>
                    </div>
                    <div class="info-title">
                      {{ story.pressTitle }}
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div class="more_btn">
                <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'一线故事' }})">点击查看更多故事
                </a-button>
              </div>
            </div>
            <div class="humanity-warp">
              <a-tabs default-active-key="1">
                <a-tab-pane key="1">
                  <span slot="tab"><a-icon type="team"></a-icon>人文软控</span>
                  <div class="humanity_center">
                    <a-row :gutter="15">
                      <a-col span="24" class="info_warp" v-for="(humanity, index) in humanityList" :key="humanity.id"
                             @click="$router.push({path:'details', query:{ id: humanity.id }})">
                        <div v-if="index < 1" class="info-img" style="border: 1px solid #ccc;">
                          <div>
                            <img v-if="humanity.picture" :src="staticDomainURL + humanity.picture">
                            <img v-else src="~@/assets/img/management1.png">
                          </div>
                        </div>
                        <div class="info-title">
                          {{ humanity.pressTitle }}
                        </div>
                      </a-col>
                    </a-row>
                    <div class="more_btn">
                      <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'人文软控' }})">
                        点击查看更多
                      </a-button>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane key="2">
                  <span slot="tab"><a-icon type="fire"></a-icon>热门文章</span>
                  <div class="humanity_center">
                    <ul class="humanity_ul">
                      <li class="humanity_li" v-for="hot in hotList" :key="hot.id"
                          @click="$router.push({path:'details', query:{ id: hot.id }})">
                        <div class="li-introduce">
                          <div class="introduce-title">{{ hot.pressTitle }}</div>
                          <div class="introduce-info">
                            {{ hot.summarize }}
                          </div>
                          <div class="introduce-time">
                            <a-icon class="time_icon" type="clock-circle"></a-icon>
                            {{ hot.releasetime }}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="more_btn">
                      <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'热门文章' }})">
                        点击查看更多
                      </a-button>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
          <div class="hot_videos">
            <div class="hot_content">
              <div class="hot_title">热门视频</div>
              <div class="title_line"></div>
              <div class="video_swiper">
                <a-row :gutter="15">
                  <a-col span="24" class="info_warp" v-for="(video, index) in videoList" :key="video.id"
                         @click="$router.push({path:'details', query:{ id: video.id }})">
                    <div v-if="index < 1" class="info-img" style="border: 1px solid #ccc;">
                      <div>
                        <img v-if="video.picture" :src="staticDomainURL + video.picture">
                        <img v-else src="~@/assets/img/management1.png">
                      </div>
                    </div>
                    <div class="info-title">
                      {{ video.pressTitle }}
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div class="more_btn">
                <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'热门视频' }})">点击查看更多
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home_bottom_warp">
      <div class="content">
        <div class="content_warp" id="last_warp">
          <div class="system_warp">
            <a-tabs default-active-key="1">
              <a-tab-pane key="1">
                <span slot="tab"><a-icon type="file-text"></a-icon>公司制度</span>
                <div class="system_list">
                  <ul class="list_ul">
                    <li class="list_li" v-for="system in systemList" :key="system.id"
                        @click="$router.push({path:'details', query:{ id: system.id, model: '公司制度' }})">
                      <span class="li_title"><span class="li_name">{{ system.filename }}</span><span
                        class="li_time">{{ system.createTime }}</span></span>
                    </li>
                  </ul>
                  <div class="more_btn">
                    <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'公司制度' }})">
                      点击查看更多
                    </a-button>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2">
                <span slot="tab"><a-icon type="file-text"></a-icon>技术标准</span>
                <div class="system_list">
                  <ul class="list_ul">
                    <li class="list_li" v-for="technical in technicalList" :key="technical.id"
                        @click="$router.push({path:'details', query:{ id: technical.id, model: '技术标准' }})">
                      <span class="li_title"><span class="li_name">{{ technical.filename }}</span><span class="li_time">{{ technical.createTime }}</span></span>
                    </li>
                  </ul>
                  <div class="more_btn">
                    <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'技术标准' }})">
                      点击查看更多
                    </a-button>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="3">
                <span slot="tab"><a-icon type="cloud-download"></a-icon>党工团资讯</span>
                <div class="system_list">
                  <ul class="list_ul">
                    <li class="list_li" v-for="file in fileList" :key="file.id"
                        @click="$router.push({path:'details', query:{ id: file.id }})">
                      <span class="li_title"><span class="li_name">{{ file.pressTitle }}</span><span
                        class="li_time">{{ file.releasetime }}</span></span>
                    </li>
                  </ul>
                  <div class="more_btn">
                    <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'党工团资讯' }})">
                      点击查看更多
                    </a-button>
                  </div>
                </div>
              </a-tab-pane>
              <a-tab-pane key="4">
                <span slot="tab"><a-icon type="cloud"></a-icon>软控报</span>
                <div class="system_list">
                  <ul class="list_ul">
                    <li class="list_li" v-for="control in controlList" :key="control.id"
                        @click="$router.push({path:'details', query:{ id: control.id }})">
                      <span class="li_title"><span class="li_name">{{ control.pressTitle }}</span><span class="li_time">{{ control.releasetime }}</span></span>
                    </li>
                  </ul>
                  <div class="more_btn">
                    <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'软控报' }})">点击查看更多
                    </a-button>
                  </div>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="product_warp">
            <div class="product">
              <div class="product_title">明星产品</div>
              <div class="title_line"></div>
              <div class="list_li" v-for="star in starList" :key="star.id"
                   @click="$router.push({path:'details', query:{ id: star.id }})">
                <div class="li_img">
                  <img v-if="star.picture" :src="staticDomainURL + star.picture">
                  <img v-else src="~@/assets/img/example_1.png">
                </div>
                <div class="li_title">
                  {{ star.pressTitle }}
                </div>
                <div class="li_info">
                  {{ star.summarize }}
                </div>
                <div class="li_time">
                  <div class="left">
                    <a-icon class="time_icon" type="clock-circle"></a-icon>
                    {{ star.releasetime }}
                  </div>
                  <div class="right">
                    <a-icon class="time_icon" type="eye"></a-icon>
                    {{ star.viewnum }}
                  </div>
                </div>
              </div>
              <div class="more_btn">
                <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'明星产品' }})">点击查看更多
                </a-button>
              </div>
            </div>
            <div class="newSoft">
              <div class="newSoft_title">新软控人</div>
              <div class="title_line"></div>
              <div class="list_li" v-for="newSoft in newSoftList" :key="newSoft.id"
                   @click="$router.push({path:'details', query:{ id: newSoft.id }})">
                <div class="li_img">
                  <img v-if="newSoft.picture" :src="staticDomainURL + newSoft.picture">
                  <img v-else src="~@/assets/img/example_1.png">
                </div>
                <div class="li_warp">
                  <div class="li_title">
                    {{ newSoft.pressTitle }}
                  </div>
                  <div class="li_time">
                    <!-- <span class="left"><a-icon class="time_icon" type="clock-circle"></a-icon>{{newSoft.releasetime}}</span> -->
                    <span class="right"><a-icon class="time_icon"
                                                type="eye"></a-icon>{{ newSoft.viewnum || '0' }}</span>
                  </div>
                </div>
              </div>
              <div class="more_btn">
                <a-button class="btn_cli" @click="$router.push({path:'detailsList', query:{ name:'新软控人' }})">点击查看更多
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TopModal :isVisible="isPanelVisible" @close="hidePanel" />
  </div>

</template>

<script>
import { getAction, postAction } from '@/api/manage'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { getParams } from 'ant-design-vue/lib/vc-form/src/utils'
import TopModal from './TopModal.vue'

export default {
  components: {
    TopModal
  },
  data() {
    return {
      userInfo: Vue.ls.get(USER_INFO),
      staticDomainURL: window._CONFIG['staticDomainURL'],
      currentHead: '0',
      currentVideo: '0',
      // 头条新闻列表
      headNewList: [],
      // 新闻中心列表
      newsList: [],
      // 通知公告列表
      noticeList: [],
      // 经营专题列表
      specialList: [],
      // 热门视频列表
      videoList: [],
      // 一线故事列表
      storyList: [],
      // 人文软控列表
      humanityList: [],
      // 热门文章列表
      hotList: [],
      // 公司制度列表
      systemList: [],
      // 技术标准列表
      technicalList: [],
      // 党工团资讯列表
      fileList: [],
      // 明星产品列表
      starList: [],
      // 新软控人列表
      newSoftList: [],
      // 软控报列表
      controlList: [],
      isPanelVisible: false,
      url: {
        queryByUserId: '/mesnac/sys/annountCement/queryByUserId'
      },
    }
  },
  created() {
    this.showPanel()
    this.getHades()
    this.getNews()
    this.getArticleList()
    this.getSystemList()
    this.gettechnicalList()
    this.$nextTick(() => {
      this.getlocal()
    })
  },
  mounted() {
    let _this = this
    this.checkUrl()
    _this.$nextTick(function() {
      window.addEventListener('scroll', _this.handleScorll)
    })
  },
  methods: {
    async showPanel() {
      console.log('展示数据')
      await getAction(this.url.queryByUserId,{ username: Vue.ls.get(USER_INFO).username }).then(res =>{
        if(res.success) {
          this.isPanelVisible = true
         } else {
          this.isPanelVisible = false
        }
      })
    },
    hidePanel() {
      this.isPanelVisible = false
    },
    checkUrl() {
      let path = this.getParamObj(window.location.href, 'path')
      console.log(path)
      let filepath = this.getParamObj(window.location.href, 'filepath').replace('%252F', '%2F')
      console.log(filepath)

      if (path && path.length > 0) {
        this.$router.push({ path: path, query: { filepath: filepath } })
      }
    },
    getParamObj(url, param) {
      const searchIndex = url.indexOf('?')
      const searchParams = url.slice(searchIndex + 1).split('&')
      for (let i = 0; i < searchParams.length; i += 1) {
        const items = searchParams[i].split('=')
        if (items[0].trim().toLowerCase() === param.toLowerCase()) {
          return items[1].trim()
        }
      }
      return null
    },
    // pressColumn:'头条新闻'
    getHades() {
      console.log('staticDomainURL', this.staticDomainURL)
      getAction('/mesnac/zmap/zmaPrescenter/list', {
        pageNo: 1,
        pageSize: 6,
        headstatus: '已设为头条',
        presssTatus: '已发布',
        column: 'releasetime',
        order: 'desc'
      }).then(res => {
        if (res.success) {
          this.headNewList = res.result.records
        }
      }).finally(() => {
        console.log('this.headNewList: ', this.headNewList)
        this.headNewList.forEach(item => {
          let day = item.releasetime.substring(8, 10)
          item.day = day
          let years = item.releasetime.substring(0, 7)
          item.years = years
        })
      })
    },
    getNews() {
      getAction('/mesnac/zmap/zmaPrescenter/list', {
        pageNo: 1,
        pageSize: 6,
        pressColumn: '新闻中心',
        presssTatus: '已发布',
        column: 'releasetime',
        order: 'desc'
      }).then(res => {
        if (res.success) {
          this.newsList = res.result.records
        }
      })
    },
    getArticleList() {
      getAction('/mesnac/zmap/zmaPrescenter/getMapList', { pressColumn: '通知公告,经营专题,热门视频,一线故事,人文软控,热门文章,公司制度,党工团资讯,明星产品,新软控人,软控报' }).then(res => {
        if (res.success) {
          this.noticeList = res.result.通知公告.slice(0, 6)
          this.specialList = res.result.经营专题.slice(0, 6)
          this.videoList = res.result.热门视频.slice(0, 6)
          this.storyList = res.result.一线故事.slice(0, 6)
          this.humanityList = res.result.人文软控.slice(0, 6)
          this.hotList = res.result.热门文章.slice(0, 7)
          // this.systemList = res.result.公司制度.slice(0,7)
          this.fileList = res.result.党工团资讯.slice(0, 7)
          this.controlList = res.result.软控报.slice(0, 7)
          this.starList = res.result.明星产品.slice(0, 1)
          this.newSoftList = res.result.新软控人.slice(0, 2)
        }
      })
    },
    getSystemList() {
      let that = this
      getAction('/mesnac/zmeResource/zmeResourceCenter/init').then(res => {
        if (res.success) {
          console.log('res.result: ', res.result)
          res.result.forEach(item => {
            if (item.filename == '公司制度') {
              that.getChildList(item)
            }
          })
        }
      })
    },
    getChildList(record) {
      console.log('record: ', record)
      getAction('/mesnac/zmeResource/zmeResourceCenter/selectByParentId', {
        parentid: record.id,
        userid: this.userInfo.id
      }).then(res => {
        if (res.success) {
          this.systemList = res.result.slice(0, 7)
          console.log('this.systemList: ', this.systemList)
        } else {
          this.systemList = []
        }
      })
    },
    gettechnicalList() {
      let that = this
      getAction('/mesnac/zmeResource/zmeResourceCenter/init').then(res => {
        if (res.success) {
          console.log('gettechnicalList:res.result: ', res.result)
          res.result.forEach(item => {
            if (item.filename == '技术标准') {
              that.gettecChildList(item)
            }
          })
        }
      })
    },
    gettecChildList(record) {
      console.log('gettecChildList:record: ', record)
      getAction('/mesnac/zmeResource/zmeResourceCenter/sel_allFile', { rootid: record.id }).then(res => {
        if (res.success) {
          this.technicalList = res.result.slice(0, 7)
          console.log('this.technicalList: ', this.technicalList)
        } else {
          this.technicalList = []
        }
      })
    },
    onChange(a) {
      this.currentHead = a
    },
    videoChange(a) {
      this.currentVideo = a
    },
    cuttingTime(time) {
      let date = time.slice(0, 10)
      return date
    },
    getlocal() {
      let select = localStorage.getItem('id')
      let elm = document.getElementById(select)
      if (select) {
        setTimeout(function() {
          elm.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        }, 500)
        // document.getElementById(select).scrollIntoView()

      }
    }
  },
  destroyed() {
    localStorage.setItem('id', '')
  }
}
</script>

<style lang="less" scoped>
// 修改tabs样式
/deep/ .ant-tabs {
  .ant-tabs-nav-container, .ant-tabs-nav-wrap, .ant-tabs-nav-scroll {
    // background-color:rgba(188, 0, 57, 100);
    background: #fff;
    height: 70px;

    .ant-tabs-nav {
      width: 100%;
      margin-top: 13px;
      height: 60px;

      .ant-tabs-tab {
        height: 60px;
        padding: 12px 0px;
        line-height: 30px;
        width: 50%;
        margin: 0px;
        text-align: center;
        color: #101010;
        font-size: 22px;

        &::after {
          content: "";
          display: inline-block;
          height: 2px;
          width: 100%;
          position: absolute;
          left: 0px;
          background: #fff;
          bottom: 2px;
          z-index: 100;
        }
      }

      .ant-tabs-tab-active {
        height: 58px;
        color: rgba(188, 0, 57, 100);
        background: #fff;
        position: relative;

        &::after {
          content: "";
          display: inline-block;
          height: 2px;
          width: 100px;
          position: absolute;
          left: 50%;
          margin-left: -50px;
          background: #BC0039;
          bottom: 2px;
          z-index: 100;
        }
      }

      .ant-tabs-ink-bar, .ant-tabs-ink-bar-animated {
        display: none !important;
      }

      .ant-tabs-content {
        background: #fff;
      }
    }
  }
}

.home-bg-warp {
  // width: 100%;
  // background: url("~@/assets/img/home_bg.png") no-repeat top center / contain;
  background: #f6f6f6;
  background-size: 100%;

  .max-warp {
    // height: 3250px;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;

    .swiper-warp {
      width: 1100px;

      .head_swiper {
        .ant-carousel /deep/ .slick-dots {
          height: auto;
          height: 90px;
          bottom: 0px;
          text-align: left;

          li {
            // background-color:rgba(198, 52, 96, 100);
            width: 100px;
            height: 90px;
            position: relative;
            z-index: 2;
            color: #fff;

            .day {
              padding-top: 8px;
              width: 100%;
              display: inline-block;
              font-size: 32px;
              font-weight: 800;
              text-align: center;

            }

            .yeas {
              display: inline-block;
              font-size: 18px;
              text-align: center;
            }

            &::before {
              content: "";
              position: absolute;
              background: rgba(198, 52, 96, 100);
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0.6;
              z-index: -1;
            }
          }

          li img {
            width: 100%;
            height: 100%;
          }

          li.slick-active {
            background: #fff;
            color: #BC0039;

            &::before {
              content: "";
              position: absolute;
              background: rgba(198, 52, 96, 100);
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
        }

        .ant-carousel /deep/ .slick-thumb ul {
          text-align: right;
          height: 90px;
        }

        .ant-carousel /deep/ .slick-thumb li {
          width: 100px;
          height: 90px;
        }

        .ant-carousel /deep/ .slick-slide {
          text-align: center;
          height: 650px;
          line-height: 220px;
          background: #ccc;
          overflow: hidden;
        }

        .ant-carousel /deep/ .custom-slick-arrow {
          width: 45px;
          height: 45px;
          font-size: 24px;
          text-align: center;
          line-height: 44px;
          background-color: rgba(188, 0, 57, 100);
          color: #fff;
          z-index: 10;

          &.slick-next {
            position: absolute;
            bottom: 0px;
            top: 100%;
            margin-top: -45px;
          }

          &.slick-prev {
            position: absolute;
            margin: 0px;
            top: 100%;
            left: 100% !important;
            margin-left: -45px;
            margin-top: -90px;
          }
        }

        .ant-carousel /deep/ .custom-slick-arrow:before {
          display: none;
        }

        .ant-carousel /deep/ .custom-slick-arrow:hover {
          opacity: 0.7;
        }

        .ant-carousel /deep/ .slick-slide h3 {
          color: #fff;
        }
      }

      img {
        width: 100%;
        height: 650px;
      }

      .headlines {
        margin-top: 4px;
        width: 100%;
        background: #fff;
        height: 144px;

        .head_content {
          width: 1000px;
          margin: 0 auto;
          padding: 15px 0px;
          cursor: pointer;

          .head-top {
            display: flex;
            justify-content: space-between;

            .top-left {
              color: rgba(198, 52, 96, 100);
              font-size: 14px;
            }

            .top-right {
              color: rgba(198, 52, 96, 100);
              font-size: 14px;

              .eye_icon {
                margin-right: 20px;
              }
            }
          }

          .head-title {
            margin-top: 10px;
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
          }

          .head-info {
            margin-top: 10px;
            color: rgba(16, 16, 16, 100);
            font-size: 14px;
            // height: 48px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; //行数
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .new-warp {
      width: 380px;
      background: #fff;

      .news_center {
        width: 360px;
        margin: 0 auto;

        .news_ul {
          list-style: none;
          padding: 0px;

          .news_li {
            border-bottom: 1px solid rgba(162, 162, 167, 49);
            margin-bottom: 15px;
            padding-left: 20px;

            .li_time {
              display: inline-block;
              color: rgba(16, 16, 16, 100);
              font-size: 14px;
              margin-bottom: 10px;

              .time_icon {
                margin-right: 10px;
                color: rgba(203, 133, 154, 100);
              }
            }

            .li_title {
              cursor: pointer;
              display: inline-block;
              width: 340px;
              color: rgba(16, 16, 16, 100);
              font-size: 18px;
              margin-bottom: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .new_more {
          margin-bottom: 15px;
          margin-right: 10px;
          text-align: right;
          color: rgba(37, 38, 41, 57);
          font-size: 14px;

          span {
            cursor: pointer;
          }
        }
      }
    }
  }

  .middle-warp {
    padding-top: 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .management {
      width: 1100px;
      background: #fff;
      border: 1px solid rgba(241, 241, 241, 100);
      display: flex;

      .ment_content {
        width: 33%;
        padding-top: 20px;
        margin-left: 10px;
        padding-right: 10px;
        position: relative;

        .ment_title {
          color: rgba(198, 52, 96, 100);
          font-size: 22px;
        }

        .title_line {
          width: 80px;
          height: 3px;
          margin-top: 10px;
          background-color: rgba(198, 52, 96, 100);
        }

        .ment_info {
          padding-top: 18px;
          padding-bottom: 50px;
        }

        .more_btn {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%);
          margin: 0 auto;
          text-align: center;

          .btn_cli {
            height: 30px;
            font-size: 14px;
            color: #252629 5700%;

            &:hover {
              color: #BC0039;
              background-color: #fff;
              border-color: #BC0039;
            }
          }
        }

        .info_warp {
          // width: 300px;
          cursor: pointer;

          .info-img {
            img {
              width: 100%;
              height: 180px;
            }
          }

          .info-title {
            margin: 15px 0px 15px 0px;
            text-indent: 4px;
            color: rgba(78, 81, 78 100);
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; //行数
            -webkit-box-orient: vertical;
          }

          .info-info {
            border-top: 2px solid rgba(0, 0, 0, 1);
            margin-bottom: 15px;
            padding-top: 15px;
            color: rgba(16, 16, 16, 100);
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; //行数
            -webkit-box-orient: vertical;
          }
        }
      }

      .story-warp {
        padding-top: 20px;
        padding-bottom: 50px;
        position: relative;
        width: 33%;
        // border: 1px solid rgba(241, 241, 241, 100);
        .ment_title {
          padding-left: 10px;
          color: rgba(198, 52, 96, 100);
          font-size: 22px;
        }

        .title_line {
          margin-left: 10px;
          width: 80px;
          height: 3px;
          margin-top: 10px;
          background-color: rgba(198, 52, 96, 100);
        }

        .story_center {
          display: flex;
          padding: 18px 10px 0px 10px;

          .info_warp {
            // width: 300px;
            cursor: pointer;

            .info-img {
              img {
                width: 100%;
                height: 180px;
              }
            }

            .info-title {
              margin: 15px 0px 15px 0px;
              text-indent: 4px;
              color: rgba(78, 81, 78 100);
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1; //行数
              -webkit-box-orient: vertical;
            }

            .info-info {
              border-top: 2px solid rgba(0, 0, 0, 1);
              margin-bottom: 15px;
              padding-top: 15px;
              color: rgba(16, 16, 16, 100);
              font-size: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2; //行数
              -webkit-box-orient: vertical;
            }
          }

          .story_left_example {
            // width: 200px;
            .example_1 {
              margin-bottom: 20px;
              cursor: pointer;

              .example_img {
                width: 100%;
                height: 220px;
              }

              .example_title {
                margin-top: 15px;
                padding: 0px 10px;
                color: rgba(16, 16, 16, 1);
                font-size: 18px;
              }
            }
          }

          .story_right_list {
            // width: 200px;
            // margin-left: 40px;
            .list_ul {
              border-bottom: 1px solid rgba(162, 162, 167, 49);
              list-style: none;
              padding: 0px;

              .list_li {
                cursor: pointer;
                padding: 17px 0px;
                font-size: 16px;

                .li_title {
                  color: rgba(16, 16, 16, 1);
                }

                .li_name {
                  vertical-align: bottom;
                  display: inline-block;
                  width: 300px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: rgba(16, 16, 16, 0.9);
                }
              }
            }
          }
        }

        .story_more {
          margin-right: 40px;
          text-align: right;

          span {
            cursor: pointer;
          }
        }

        .more_btn {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%);
          text-align: center;

          .btn_cli {
            height: 30px;
            font-size: 14px;
            color: #252629 5700%;

            &:hover {
              color: #BC0039;
              background-color: #fff;
              border-color: #BC0039;
            }
          }
        }
      }

      .humanity-warp {
        width: 33%;
        // border: 1px solid rgba(241, 241, 241, 100);
        /deep/ .ant-tabs {
          .ant-tabs-nav-container, .ant-tabs-nav-wrap, .ant-tabs-nav-scroll {
            // background-color:rgba(188, 0, 57, 100);
            background: #fff;
            height: 60px;

            .ant-tabs-nav {
              width: 100%;
              margin-top: 5px;
              height: 60px;

              .ant-tabs-tab {
                height: 55px;
                line-height: 20px;
                width: 50%;
                margin: 0px;
                text-align: center;
                color: #101010;
                font-size: 22px;

                &::after {
                  content: "";
                  display: inline-block;
                  height: 2px;
                  width: 100%;
                  position: absolute;
                  left: 0px;
                  background: #fff;
                  bottom: 2px;
                  z-index: 100;
                }
              }

              .ant-tabs-tab-active {
                height: 60px;
                color: rgba(188, 0, 57, 100);
                background: #fff;

                &::after {
                  content: "";
                  display: inline-block;
                  height: 2px;
                  width: 100px;
                  position: absolute;
                  left: 50%;
                  margin-left: -50px;
                  background: #BC0039;
                  bottom: 5px;
                  z-index: 100;
                }
              }

              .ant-tabs-ink-bar, .ant-tabs-ink-bar-animated {
                display: none !important;
              }

              .ant-tabs-content {
                background: #fff;
              }
            }
          }
        }

        .humanity_center {
          position: relative;
          padding: 5px 10px 50px 10px;

          .info_warp {
            // width: 300px;
            cursor: pointer;

            .info-img {
              img {
                width: 100%;
                height: 180px;
              }
            }

            .info-title {
              margin: 15px 0px 15px 0px;
              text-indent: 4px;
              color: #2c3e50;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1; //行数
              -webkit-box-orient: vertical;
            }

            .info-info {
              border-top: 2px solid rgba(0, 0, 0, 1);
              margin-bottom: 15px;
              padding-top: 15px;
              color: rgba(16, 16, 16, 100);
              font-size: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2; //行数
              -webkit-box-orient: vertical;
            }
          }
        }

        .humanity_ul {
          list-style: none;
          padding: 0px;

          .humanity_li {
            cursor: pointer;
            border-bottom: 1px solid rgba(162, 162, 167, 49);
            margin-bottom: 10px;
            display: flex;

            .li-img {
              width: 220px;

              img {
                width: 220px;
                height: 160px;
              }
            }

            .li-introduce {
              width: 100%;
              margin-left: 0px;
              margin-bottom: 7px;

              .introduce-time {
                margin-top: 5px;
                text-align: right;
                color: rgba(16, 16, 16, 0.8);
                font-size: 14px;

                .time_icon {
                  color: #BC0039;
                  margin-right: 5px;
                }
              }

              .introduce-title {
                margin-bottom: 5px;
                color: rgba(16, 16, 16, 1);
                font-size: 18px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; //行数
                -webkit-box-orient: vertical;
              }

              .introduce-info {
                color: rgba(16, 16, 16, 0.8);
                font-size: 14px;
                // min-height: 66px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; //行数
                -webkit-box-orient: vertical;
              }
            }
          }
        }

        .humanity_more {
          margin-bottom: 10px;
          text-align: right;

          span {
            cursor: pointer;
          }
        }

        .more_btn {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%);
          text-align: center;

          .btn_cli {
            height: 30px;
            font-size: 14px;
            color: #252629 5700%;

            &:hover {
              color: #BC0039;
              background-color: #fff;
              border-color: #BC0039;
            }
          }
        }
      }
    }

    .hot_videos {
      width: 380px;
      position: relative;
      background: #fff;
      border: 1px solid rgba(241, 241, 241, 100);

      .hot_content {
        padding-top: 20px;
        padding: 20px 10px 0px 10px;
        width: 380px;
        margin: 0 auto;

        .hot_title {
          color: rgba(198, 52, 96, 100);
          font-size: 22px;
        }

        .title_line {
          width: 80px;
          height: 3px;
          margin-top: 10px;
          background-color: rgba(198, 52, 96, 100);
        }

        .video_swiper {
          margin-top: 18px;

          .info_warp {
            // width: 300px;
            cursor: pointer;

            .info-img {
              img {
                width: 100%;
                height: 180px;
              }
            }

            .info-title {
              margin: 15px 0px 15px 0px;
              text-indent: 4px;
              color: rgba(78, 81, 78 100);
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1; //行数
              -webkit-box-orient: vertical;
            }

            .info-info {
              border-top: 2px solid rgba(0, 0, 0, 1);
              margin-bottom: 15px;
              padding-top: 15px;
              color: rgba(16, 16, 16, 100);
              font-size: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2; //行数
              -webkit-box-orient: vertical;
            }
          }

          .ant-carousel /deep/ .slick-slide {
            text-align: center;
            height: 220px;
            line-height: 220px;
            background: #ccc;
            overflow: hidden;
          }

          .ant-carousel /deep/ .custom-slick-arrow {
            width: 30px;
            height: 44px;
            font-size: 24px;
            text-align: center;
            line-height: 44px;
            opacity: 0.4;
            background-color: rgba(37, 38, 41, 1);
            color: #fff;
            opacity: 0.3;
          }

          .ant-carousel /deep/ .custom-slick-arrow:before {
            display: none;
          }

          .ant-carousel /deep/ .custom-slick-arrow:hover {
            opacity: 0.5;
          }

          .ant-carousel /deep/ .slick-slide .video_img {
            width: 100%;
            height: 220px;
          }
        }

        .video_title {
          margin-top: 20px;
          color: rgba(16, 16, 16, 100);
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2; //行数
          -webkit-box-orient: vertical;
        }

        .video_info {
          margin-top: 10px;
          color: rgba(16, 16, 16, 0.8);
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; //行数
          -webkit-box-orient: vertical;
        }

        .video_time {
          margin-top: 20px;
          font-size: 14px;
          color: #101010;

          .time_icon {
            color: #BC0039;
          }
        }
      }

      .more_btn {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%);
        text-align: center;

        .btn_cli {
          height: 30px;
          font-size: 14px;
          color: #252629 5700%;

          &:hover {
            color: #BC0039;
            background-color: #fff;
            border-color: #BC0039;
          }
        }
      }
    }
  }

  .last-warp {
    padding-top: 50px;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .story-warp {
      width: 900px;
      border: 1px solid rgba(241, 241, 241, 100);

      .story_center {
        display: flex;
        padding: 30px 20px 0px 20px;

        .story_left_example {
          width: 400px;

          .example_1 {
            margin-bottom: 20px;
            cursor: pointer;

            .example_img {
              width: 100%;
              height: 220px;
            }

            .example_title {
              margin-top: 15px;
              padding: 0px 10px;
              color: rgba(16, 16, 16, 1);
              font-size: 18px;
            }
          }
        }

        .story_right_list {
          width: 400px;
          margin-left: 40px;

          .list_ul {
            border-bottom: 1px solid rgba(162, 162, 167, 49);
            list-style: none;
            padding: 0px;

            .list_li {
              cursor: pointer;
              padding: 17px 0px;
              font-size: 16px;

              .li_title {
                color: rgba(16, 16, 16, 1);
              }

              .li_name {
                vertical-align: bottom;
                display: inline-block;
                width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: rgba(16, 16, 16, 0.9);
              }
            }
          }
        }
      }

      .story_more {
        margin-right: 40px;
        text-align: right;

        span {
          cursor: pointer;
        }
      }
    }

    // .humanity-warp{
    //     width: 565px;
    //     border: 1px solid rgba(241, 241, 241, 100);
    //     .humanity_center{
    //         padding: 30px 20px 0px 20px;
    //     }
    //     .humanity_ul{
    //         list-style: none;
    //         padding: 0px;
    //         .humanity_li {
    //             cursor: pointer;
    //             border-bottom: 1px solid rgba(162, 162, 167, 49);
    //             margin-bottom: 20px;
    //             display: flex;
    //             .li-img{
    //                 width: 220px;
    //                 img{
    //                     width: 220px;
    //                     height: 160px;
    //                 }
    //             }
    //             .li-introduce {
    //                 margin-left: 15px;
    //                 margin-bottom: 30px;
    //                 .introduce-time {
    //                     margin-bottom: 15px;
    //                     color: rgba(16, 16, 16, 0.8);
    //                     font-size: 14px;
    //                     .time_icon{
    //                         color: #BC0039;

    //                     }
    //                 }
    //                 .introduce-title{
    //                     margin-bottom: 20px;
    //                     color: rgba(16, 16, 16, 1);
    //                     font-size: 18px;
    //                     overflow: hidden;
    //                     text-overflow: ellipsis;
    //                     display: -webkit-box;
    //                     -webkit-line-clamp: 1; //行数
    //                     -webkit-box-orient: vertical;
    //                 }
    //                 .introduce-info{
    //                     color: rgba(16, 16, 16, 0.8);
    //                     font-size: 14px;
    //                     overflow: hidden;
    //                     text-overflow: ellipsis;
    //                     display: -webkit-box;
    //                     -webkit-line-clamp: 3; //行数
    //                     -webkit-box-orient: vertical;
    //                 }
    //             }
    //         }
    //     }
    //     .humanity_more{
    //         margin-bottom: 10px;
    //         text-align: right;
    //         span {cursor: pointer;}
    //     }
    // }
  }

}

.home_bottom_warp {
  padding-top: 14px;
  background: rgba(246, 246, 246, 100);

  .content_warp {
    display: flex;
    justify-content: space-between;

    .system_warp {
      margin-bottom: 10px;
      width: 670px;
      // 修改tabs样式
      /deep/ .ant-tabs {
        background: #fff;

        .ant-tabs-nav-container, .ant-tabs-nav-wrap, .ant-tabs-nav-scroll {
          // background-color:rgba(188, 0, 57, 100);
          background: #fff;
          height: 70px;

          .ant-tabs-nav {
            width: 75%;
            margin-top: 13px;
            height: 60px;

            .ant-tabs-tab {
              height: 60px;
              padding: 12px 0px;
              line-height: 30px;
              width: 33%;
              margin: 0px;
              text-align: center;
              color: #101010;
              font-size: 22px;

              &::after {
                content: "";
                display: inline-block;
                height: 2px;
                width: 100%;
                position: absolute;
                left: 0px;
                background: #fff;
                bottom: 2px;
                z-index: 100;
              }
            }

            .ant-tabs-tab-active {
              height: 58px;
              color: rgba(188, 0, 57, 100);
              background: #fff;
              position: relative;

              &::after {
                content: "";
                display: inline-block;
                height: 2px;
                width: 100px;
                position: absolute;
                left: 50%;
                margin-left: -50px;
                background: #BC0039;
                bottom: 2px;
                z-index: 100;
              }
            }

            .ant-tabs-ink-bar, .ant-tabs-ink-bar-animated {
              display: none !important;
            }

            .ant-tabs-content {
              background: #fff;
            }
          }
        }

        .ant-tabs-bar {
          // background: #fff;
          // margin: 0px;
          // padding: 0 0 16px 0px;
        }
      }

      // border: 1px solid rgba(241, 241, 241, 100);
      .system_list {
        background: #fff;
        padding: 30px;
        padding-bottom: 88px;

        .list_ul {
          list-style: none;
          padding: 0px;
          margin-bottom: 20px;

          .list_li {
            cursor: pointer;
            padding: 25px 0px 15px 0px;
            font-size: 18px;
            color: rgba(16, 16, 16, 100);
            border-bottom: 1px solid rgba(162, 162, 167, 49);
          }

          .li_name {
            margin-right: 50px;
            width: 330px;
            display: inline-block;
            vertical-align: bottom;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .more_btn {
          position: absolute;
          bottom: 30px;
          left: 50%;
          transform: translate(-50%);
          margin: 0 auto;
          text-align: center;

          .btn_cli {
            height: 30px;
            font-size: 14px;
            color: #252629 5700%;

            &:hover {
              color: #BC0039;
              background-color: #fff;
              border-color: #BC0039;
            }
          }
        }
      }
    }

    .product_warp {
      // background: #fff;
      width: 800px;
      border: 1px solid rgba(241, 241, 241, 100);
      padding-bottom: 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      background: #fff;

      .product {
        margin-left: 10px;
        padding-bottom: 52px;
        position: relative;
        width: 50%;

        .product_title {
          padding-left: 10px;
          padding-top: 20px;
          color: rgba(198, 52, 96, 100);
          font-size: 22px;
        }

        .title_line {
          width: 80px;
          height: 3px;
          margin-top: 10px;
          background-color: rgba(198, 52, 96, 100);
        }

        .list_li {
          margin-top: 20px;
          cursor: pointer;
          break-inside: avoid; /*防止断点*/
          border: 1px solid rgba(187, 187, 187, 100);
          background: #fff;
          width: 387px;
          margin-bottom: 18px;

          .li_img {
            width: 100%;

            img {
              width: 100%;
              height: 240px;
            }
          }

          .li_title {
            width: 300px;
            margin: 0 auto;
            // height: 60px;
            margin-top: 20px;
            font-size: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; //行数
            -webkit-box-orient: vertical;
            color: rgba(16, 16, 16, 100);
          }

          .li_info {
            width: 300px;
            // height: 93px;
            margin: 0 auto;
            font-size: 14px;
            color: rgba(16, 16, 16, 0.8);
            margin-bottom: 32px;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4; //行数
            -webkit-box-orient: vertical;
          }

          .li_time {
            padding-bottom: 25px;
            width: 300px;
            font-size: 14px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .left {
              .time_icon {
                margin-right: 10px;
                color: #BC0039;
              }
            }

            .right {
              color: #BC0039;

              .eye_icon {
                margin-right: 10px;
              }
            }
          }
        }

        .more_btn {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%);
          margin: 0 auto;
          text-align: center;

          .btn_cli {
            height: 30px;
            font-size: 14px;
            color: #252629 5700%;

            &:hover {
              color: #BC0039;
              background-color: #fff;
              border-color: #BC0039;
            }
          }
        }
      }

      .newSoft {
        position: relative;
        padding-bottom: 52px;
        width: 50%;

        .newSoft_title {
          padding-left: 10px;
          padding-top: 20px;
          color: rgba(198, 52, 96, 100);
          font-size: 22px;
        }

        .title_line {
          width: 80px;
          height: 3px;
          margin-top: 10px;
          background-color: rgba(198, 52, 96, 100);
        }

        .list_li {
          margin-top: 16px;
          cursor: pointer;
          break-inside: avoid; /*防止断点*/
          // border: 1px solid rgba(187, 187, 187, 100);
          background: #fff;
          width: 387px;
          margin-bottom: 10px;

          .li_img {
            width: 100%;
            text-align: center;

            img {
              max-width: 387px;
              max-height: 200px;
            }
          }

          .li_warp {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
          }

          .li_title {
            width: 550px;
            margin: 0 auto;
            margin-left: 10px;
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; //行数
            -webkit-box-orient: vertical;
            color: rgba(16, 16, 16, 100);
          }

          .li_info {
            width: 300px;
            // height: 93px;
            margin: 0 auto;
            font-size: 14px;
            color: rgba(16, 16, 16, 0.8);
            margin-bottom: 32px;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4; //行数
            -webkit-box-orient: vertical;
          }

          .li_time {
            // padding-bottom: 25px;
            text-align: right;
            width: 50px;
            margin: 0 auto;
            font-size: 12px;
            margin-right: 10px;
            display: flex;
            margin-top: 5px;
            display: -webkit-box;
            -webkit-line-clamp: 1; //行数
            -webkit-box-orient: vertical;
            justify-content: space-between;

            .left {
              .time_icon {
                margin-right: 10px;
                color: #BC0039;
              }
            }

            .right {
              color: #BC0039;

              .eye_icon {
                margin-right: 10px;
              }
            }
          }
        }

        .more_btn {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%);
          margin: 0 auto;
          text-align: center;

          .btn_cli {
            height: 30px;
            font-size: 14px;
            color: #252629 5700%;

            &:hover {
              color: #BC0039;
              background-color: #fff;
              border-color: #BC0039;
            }
          }
        }
      }

      .product_list_warp {
        margin-bottom: 20px;
        column-count: 2;
        // column-gap: 10px;
        .list_li {
          cursor: pointer;
          break-inside: avoid; /*防止断点*/
          border: 1px solid rgba(187, 187, 187, 100);
          background: #fff;
          width: 387px;
          margin-bottom: 18px;

          .li_img {
            width: 100%;

            img {
              width: 100%;
              height: 240px;
            }
          }

          .li_title {
            width: 300px;
            margin: 0 auto;
            height: 60px;
            margin-top: 20px;
            font-size: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; //行数
            -webkit-box-orient: vertical;
            color: rgba(16, 16, 16, 100);
          }

          .li_info {
            width: 300px;
            height: 93px;
            margin: 0 auto;
            font-size: 14px;
            color: rgba(16, 16, 16, 0.8);
            margin-bottom: 32px;
            margin-top: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4; //行数
            -webkit-box-orient: vertical;
          }

          .li_time {
            padding-bottom: 25px;
            width: 300px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .left {
              .time_icon {
                margin-right: 10px;
                color: #BC0039;
              }
            }

            .right {
              color: #BC0039;

              .eye_icon {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.home_more {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  // margin-bottom: 40px;
  span {
    cursor: pointer;
    font-size: 14px;
    color: #252629 5700%;

    &:hover {
      color: #BC0039;
    }
  }
}

</style>


