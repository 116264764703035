<template>
  <a-modal
    title="更新系统说明"
    :width="modalWidth"
    :visible="isVisible"
    :footer="null"
    @cancel="closePanel"
    cancelText="关闭">
    <div>
      <!-- 悬浮层的内容 -->
      <div v-for="(item, index) in items" :key="index">
        <h2 style="font-size:18px">{{ item.sendTime }}</h2>
        <h2 style="font-size:15px;text-indent:2em;">{{ item.msgContent }}</h2>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'

export default {
  props: {
    isVisible: Boolean
  },
  created() {
    this.setData();
  },
  computed: {
    modalWidth() {
      return 350
    }
  },
  data() {
    return {
      items: [],
      url: {
        queryByUserId: '/mesnac/sys/annountCement/queryByUserId',
        readAllByUser: '/mesnac/sys/sysAnnouncementSend/readAllByUser'
      }
    }
  },
  methods: {
    async setData(){
      await getAction(this.url.queryByUserId,{ username: Vue.ls.get(USER_INFO).username }).then(res =>{
        if(res.success) {
        console.log(res)
          this.items = res.result
        }
      })
    },
    closePanel() {
      console.log('清空未读')
      getAction(this.url.readAllByUser,{ username: Vue.ls.get(USER_INFO).username }).then((res) => {
        if (res.success) {
           this.$emit('close')
        }
      })
    }
  }
}
</script>

<style>

</style>